/* eslint-disable */
import { EventEmitter } from './EventEmitter';

const wait = (time) => new Promise((resolve) => setTimeout(resolve, time));
let documentTouchStartHandler;
let documentTouchMoveHandler;
let documentTouchEndHandler;
let preventScroll;
class Menu extends EventEmitter {
	constructor() {
		super();
		this.submenus = [];
		this.status = 'close';
		this.moveStatus = 'no_move';
		this.submenuOpen = false;
	}

	get isMobile() {
		return this.resizeObserver.mobile;
	}

	get isClose() {
		return this.status === 'close';
	}

	get isOpen() {
		return this.status === 'open';
	}

	get isDuringMove() {
		return this.moveStatus === 'during_move';
	}

	get isWaitingForMove() {
		return this.moveStatus === 'waiting_for_move';
	}

	setNode(node) {
		this.node = node;
		return this;
	}

	setResizeObserver(resizeObserver) {
		this.resizeObserver = resizeObserver;
		return this;
	}

	setListNode(node) {
		this.listNode = node;
		return this;
	}

	setConfig(config) {
		this.config = config;
		return this;
	}

	setReturnButtonNode(node) {
		this.returnButtonNode = node;
		return this;
	}

	addSubmenu(submenu) {
		this.submenus.push(submenu);
		submenu.on('before:open', this.beforeSubmenuOpenHandler.bind(this));
		submenu.on('after:open', this.afterSumbmenuOpenHandler.bind(this));
		submenu.on('after:close', this.afterSumbmenuCloseHandler.bind(this));
	}

	getReverseType(type) {
		return type === 'right' ? 'left' : 'right';
	}

	closeSubmenusWithout(submenu) {
		this.submenus
			.filter((s) => s !== submenu)
			.map((s) => s.close());
	}

	toggle() {
		if (this.isOpen) this.close();
		else this.open();
	}

	open() {
		this.status = 'open';
		this.moveStatus = 'no_move';
		this.addPreventScroll();
		this.applyViewChanges();
		this.emit('open');
	}

	close() {
		this.status = 'close';
		this.moveStatus = 'no_move';
		this.submenuOpen = false;
		this.addPreventScroll();
		this.applyViewChanges();
		this.closeSubmenusWithout(null);
		this.emit('close');
	}

	beforeSubmenuOpenHandler(submenu) {
		this.closeSubmenusWithout(submenu);
	}

	afterSumbmenuOpenHandler(submenu) {
		this.submenuOpen = true;
		this.applyViewChanges();
	}

	afterSumbmenuCloseHandler(submenu) {
		this.submenuOpen = this.submenus
			.filter((submenu) => submenu.isOpen)
			.length > 0;
	}

	getTouchPositionInPercents(ev) {
		const x = ev.touches[0].clientX;
		const y = ev.touches[0].clientY;

		const position = {
			top: y / window.innerHeight,
			right: 1 - x / window.innerWidth,
			bottom: 1 - y / window.innerHeight,
			left: x / window.innerWidth,
		};

		Object.keys(position).map((key) => {
			if (position[key] > 1) position[key] = 1;
			if (position[key] < 0) position[key] = 0;
		});

		return position;
	}

	isStartPosition(position) {
		if (this.isOpen && 1 - position[this.config.mobile.type] < this.config.mobile.maxStartPercent) return true;
		if (this.isClose && position[this.config.mobile.type] < this.config.mobile.maxStartPercent) return true;
		return false;
	}

	setStartPosition(position) {
		this.startPosition = position;
		this.moveStatus = 'waiting_for_move';
	}

	clearStartPoint() {
		this.startPosition = null;
		this.moveStatus = 'no_move';
		this.applyViewChanges();
	}

	getMove(position) {
		return {
			y: position.top - this.startPosition.top,
			x: position.left - this.startPosition.left,
		};
	}

	getDirection(position) {
		const move = this.getMove(position);

		const directions = {
			leftToRight: move.x > this.config.mobile.minMovePercent,
			rightToLeft: -move.x > this.config.mobile.minMovePercent,
			topToBottom: move.y > this.config.mobile.minMovePercent,
			bottomToTop: -move.y > this.config.mobile.minMovePercent,
		};
		directions.found = Object
			.keys(directions)
			.filter((key) => directions[key]).length > 0;

		return directions;
	}

	preventScroll() {
		if ((this.isOpen || this.isDuringMove) && (this.isMobile === true)) window.scrollTo(0, this.scrollYPosition);
	}

	addPreventScroll() {
		this.scrollYPosition = window.scrollY;
	}

	removePreventScroll() {
		this.scrollYPosition = null;
	}

	startMove(position) {
		this.position = position;
		this.moveStatus = 'during_move';
		this.addPreventScroll();
		this.applyViewChanges();
	}

	checkMove(position) {
		const direction = this.getDirection(position);

		if (!direction.found) return false;
		const correctDirection = ({
			open: {
				left: 'rightToLeft',
				right: 'leftToRight',
			},
			close: {
				right: 'rightToLeft',
				left: 'leftToRight',
			},
		})[this.status][this.config.mobile.type];

		if (direction[correctDirection]) return this.startMove(position);

		this.clearStartPoint();
	}

	move(position) {
		this.position = position;
		this.emit('move', position[this.config.mobile.type]);
		this.applyViewChanges();
	}

	checkEndPoint(position) {
		if (this.isClose) {
			const { type } = this.config.mobile;
			if (position[type] > this.config.mobile.minEndMovePercent) this.open();
			else this.close();
		} else {
			const type = this.getReverseType(this.config.mobile.type);
			if (position[type] > this.config.mobile.minEndMovePercent) this.close();
			else this.open();
		}
	}

	applyViewChanges() {
		const openClass = 'menu-open';
		const closeClass = 'menu-close';
		const submenuOpenClass = 'submenu-open';

		if (this.isDuringMove) this.node.style.transform = `translateX(${this.position.left * 100}%)`;
		else this.node.style.transform = null;

		if (this.isOpen && !this.isDuringMove) this.node.classList.add(openClass);
		else this.node.classList.remove(openClass);

		if (this.isClose && !this.isDuringMove) this.node.classList.add(closeClass);
		else this.node.classList.remove(closeClass);

		if (this.submenuOpen) this.listNode.classList.add(submenuOpenClass);
		else this.listNode.classList.remove(submenuOpenClass);
	}

	async returnButtonClickHandler() {
		this.submenuOpen = false;
		this.applyViewChanges();

		await wait(300);
		this.closeSubmenusWithout(null);
	}

	documentTouchStartHandler(ev) {
		const position = this.getTouchPositionInPercents(ev);
		if (this.isStartPosition(position)) this.setStartPosition(position);
	}

	documentTouchMoveHandler(ev) {
		const position = this.getTouchPositionInPercents(ev);
		if (this.isDuringMove) this.move(position);
		if (this.isWaitingForMove) this.checkMove(position);
	}

	documentTouchEndHandler(ev) {
		if (!this.isDuringMove) return this.clearStartPoint();

		this.checkEndPoint(this.position);
	}

	initMobile() {
		if (!this.isMobile) return false;

		documentTouchStartHandler = this.documentTouchStartHandler.bind(this);
		documentTouchMoveHandler = this.documentTouchMoveHandler.bind(this);
		documentTouchEndHandler = this.documentTouchEndHandler.bind(this);
		preventScroll = this.preventScroll.bind(this);

		document.addEventListener('touchstart', documentTouchStartHandler);
		document.addEventListener('touchend', documentTouchEndHandler);
		document.addEventListener('scroll', preventScroll);
		this.returnButtonNode.addEventListener('click', this.returnButtonClickHandler.bind(this));

		this.applyViewChanges();
	}

	initDesktop() {
		if (this.isMobile) return false;

		document.removeEventListener('touchstart', documentTouchStartHandler);
		document.removeEventListener('touchmove', documentTouchMoveHandler);
		document.removeEventListener('touchend', documentTouchEndHandler);
		document.removeEventListener('scroll', preventScroll);

		this.removePreventScroll();

		this.applyViewChanges();
	}

	init() {
		this.initMobile();
		this.initDesktop();
		this.resizeObserver.on('resize:desktop', this.initDesktop.bind(this));
		this.resizeObserver.on('resize:mobile', this.initMobile.bind(this));
	}
}

export { Menu };
