/* eslint-disable */
import { EventEmitter } from './EventEmitter';

class ResizeObserver extends EventEmitter {
	constructor(maxMobileSize) {
		super();
		this.maxMobileSize = maxMobileSize;
		this.mobile = false;
		this.init();
	}

	get widthMobile() {
		return this.maxMobileSize > window.innerWidth;
	}

	setData() {
		if (this.widthMobile && !this.mobile) this.setMobile();
		else if (!this.widthMobile && this.mobile) this.setDesktop();
	}

	setMobile() {
		this.mobile = true;
		this.emit('resize:mobile');
	}

	setDesktop() {
		this.mobile = false;
		this.emit('resize:desktop');
	}

	windowResizeHandler() {
		this.setData();
	}

	init() {
		window.addEventListener('resize', this.windowResizeHandler.bind(this));
		this.setData();
	}
}

export { ResizeObserver };
