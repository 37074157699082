/* eslint-disable */
const wait = (time) => new Promise((resolve) => setTimeout(resolve, time));
class MobileButton {
	constructor() {
		this.percent = 0;
		this.status = 'static';
	}

	get isDuringAnimation() {
		return this.status === 'during_animation';
	}

	setMainNode(node) {
		this.mainNode = node;
		return this;
	}

	setTopNode(node) {
		this.topNode = node;
		return this;
	}

	setCenterNode(node) {
		this.centerNode = node;
		return this;
	}

	setBottomNode(node) {
		this.bottomNode = node;
		return this;
	}

	setMenu(menu) {
		this.menu = menu;
		menu.on('move', this.menuMoveHandler.bind(this));
		menu.on('close', this.menuCloseHandler.bind(this));
		menu.on('open', this.menuOpenHandler.bind(this));
		return this;
	}

	menuMoveHandler(menu, percent) {
		this.percent = percent;
		// this.applyViewChanges();
	}

	menuCloseHandler() {
		this.animatePercent(0);
	}

	menuOpenHandler() {
		this.animatePercent(1);
	}

	async animatePercent(percent) {
		const step = (percent - this.percent) / 20;
		this.status = 'during_animation';
		while (this.isDuringAnimation) {
			this.percent += step;
			if (this.percent > 1) this.percent = 1;
			if (this.percent < 0) this.percent = 0;
			// if (this.percent === 0 || this.percent === 1) this.status = 'static';
			// this.applyViewChanges();
			await wait(10);
		}
	}

	// applyViewChanges() {
	// 	if (this.percent < 0.5) {
	// 		const percent = this.percent * 2;
	// 		this.centerNode.style.opacity = percent < 0.5 ? 1 - percent * 2 : 0;
	// 		this.topNode.style.transform = `translateY(${(1 - percent) * -200}%)`;
	// 		this.topNode.style.backgroundColor = '#151515';
	// 		this.bottomNode.style.transform = `translateY(${(1 - percent) * 200}%)`;
	// 		this.bottomNode.style.backgroundColor = '#151515';
	// 	} else {
	// 		const percent = (this.percent - 0.5) * 2;
	// 		this.centerNode.style.opacity = 0;
	// 		this.topNode.style.transform = `rotate(${percent * 45}deg)`;
	// 		this.topNode.style.backgroundColor = '#151515';
	// 		this.bottomNode.style.transform = `rotate(${percent * -45}deg)`;
	// 		this.bottomNode.style.backgroundColor = '#151515';
	// 	}
	// }

	init() {
		this.mainNode.addEventListener('click', this.menu.toggle.bind(this.menu));
	}
}

export { MobileButton };
