/* eslint-disable */
/**
 * Klasa implementująca metody do emitowania i nasłuchiwania na eventy
 * @property {Object} _observators - obiekt przetrzymujący pary "event: [callback, callback, ... ]"
 */
class EventEmitter {
	constructor() {
		this._observators = {};
	}

	/**
	 * Pozwala nasłuchiwać innym obiektom na event emitowany przez ten obiekt
	 * @param {String} event
	 * @param {Function} callback
	 */
	on(event, callback) {
		if (!Array.isArray(this._observators[event])) this._observators[event] = [];
		this._observators[event].push(callback);
	}

	/**
	 * Pozwala wyemitować event do obserwatorów
	 * @param {String} event
	 * @param {Object} data - Obiekt który zostanie przekazany do wywoływanego callbacku
	 */
	emit(event, data) {
		if (!Array.isArray(this._observators[event])) return false;

		this._observators[event].map((callback) => {
			callback(this, data);
		});
	}
}

export {
	EventEmitter,
};
