/* eslint-disable */
import { EventEmitter } from './EventEmitter';
import { wait } from './utils';

class Submenu extends EventEmitter {
	constructor() {
		super();
		this.status = 'close'; // ENUM: open, close, during_open, during_close
		this.overlay = document.querySelector('#page-global-overlay');
	}

	get isOpen() {
		return this.status === 'open';
	}

	get isClose() {
		return this.status === 'close';
	}

	get isDuringOpen() {
		return this.status === 'during_open';
	}

	get isDuringClose() {
		return this.status === 'during_close';
	}

	setMenu(menu) {
		this.menu = menu;
		return this;
	}

	setNode(node) {
		this.node = node;
		return this;
	}

	setLinkNode(linkNode) {
		this.linkNode = linkNode;
		return this;
	}

	applyViewChanges() {
		if (!this.isClose) this.node.classList.add('show');
		else this.node.classList.remove('show');

		if (this.isDuringOpen) this.node.classList.add('during-open');
		else this.node.classList.remove('during-open');

		if (this.isDuringClose) this.node.classList.add('during-close');
		else this.node.classList.remove('during-close');

		if (this.isOpen) this.node.classList.add('open');
		else this.node.classList.remove('open');
	}

	async open() {
		if (this.isOpen) return true;

		const animation = !this.menu.submenuOpen;

		this.emit('before:open');

		if (animation) {
			this.status = 'during_open';
			this.applyViewChanges();
			await wait(30);
		}

		document.addEventListener('click', this.documentClickHandler.bind(this));

		this.status = 'open';
		this.applyViewChanges();
		this.setOverlay('open');

		this.emit('after:open');
	}

	async close() {
		if (this.isClose) return true;

		this.emit('before:close');

		document.removeEventListener('click', this.documentClickHandler.bind(this));

		this.status = 'during_close';
		this.applyViewChanges();

		this.status = 'close';
		this.applyViewChanges();
		this.setOverlay('close');

		this.emit('after:close');
	}

	toggle() {
		if (this.isOpen) this.close();
		else this.open();
	}

	documentClickHandler(ev) {
		const { target } = ev;
		if (this.node.contains(target)) return false;
		if (this.node === target) return false;
		if (this.menu.isMobile) return false;

		this.close();
	}

	linkNodeClickHandler() {
		this.toggle();
	}

	init() {
		this.linkNode.addEventListener('click', this.linkNodeClickHandler.bind(this));
	}

	setOverlay(action) {
		if (!this.overlay) return;
		if (action === 'close') this.overlay.classList.remove('active');
		else if (action === 'open') this.overlay.classList.add('active');
	}
}

export { Submenu };
