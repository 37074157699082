import { MenuBuilder } from './MenuBuilder';
import { MobileButtonBuilder } from './MobileButtonBuilder';
import { ResizeObserver } from './ResizeObserver';

(async () => {
	const resizeObserver = new ResizeObserver(1200);

	const menuBuilder = new MenuBuilder(resizeObserver);
	window.menu = await menuBuilder.build('[data-navbar-content]');

	const menuButtonBuilder = new MobileButtonBuilder(window.menu);
	window.menuButton = menuButtonBuilder.build('[data-navbar-button]');
})();
