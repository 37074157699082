/* eslint-disable */
import { MobileButton } from './MobileButton';

class MobileButtonBuilder {
	constructor(menu) {
		this.menu = menu;
	}

	build(selector) {
		const mobileButton = new MobileButton();
		const node = document.querySelector(selector);

		mobileButton
			.setMainNode(node)
			.setTopNode(node.querySelector('[data-navbar-button-top]'))
			.setCenterNode(node.querySelector('[data-navbar-button-center]'))
			.setBottomNode(node.querySelector('[data-navbar-button-bottom]'))
			.setMenu(this.menu)
			.init();

		return mobileButton;
	}
}

export { MobileButtonBuilder };
