/* eslint-disable */
import { Menu } from './Menu';
import { SubmenuBuilder } from './SubmenuBuilder';

window.HTMLCollection.prototype.toArray = function () { return [...this]; };

class MenuBuilder {
	constructor(resizeObserver) {
		this.resizeObserver = resizeObserver;
	}

	build(selector) {
		if (document.readyState === 'complete') return this.createMenu(selector);

		return new Promise((resolve) => {
			document.addEventListener('DOMContentLoaded', () => {
				resolve(this.createMenu(selector));
			});
		});
	}

	createMenu(selector) {
		const menu = new Menu();

		const node = document.querySelector(selector);
		const listNode = node.querySelector('[data-navbar-menu]');
		const returnButtonNode = document.querySelector('.navbar-toggler');

		menu
			.setNode(node)
			.setListNode(listNode)
			.setReturnButtonNode(returnButtonNode)
			.setResizeObserver(this.resizeObserver)
			.setConfig({
				mobile: {
					type: 'right', // Z której strony jest menu 'left', 'right'
					maxStartPercent: 0.2, //
					minMovePercent: 0.15, // Minimalne przesunięcie po którym nastąpi określenie kierunku
					minEndMovePercent: 0.5, //
				},
			})
			.init();

		const submenuBuilder = new SubmenuBuilder(menu);

		// create submenus
		node
			.querySelector('[data-navbar-menu]')
			.children
			.toArray()
			.filter((node) => node.classList.contains('item-has-submenu'))
			.map((node) => submenuBuilder.build(node))
			.map((submenu) => menu.addSubmenu(submenu));

		return menu;
	}
}

export { MenuBuilder };
