/* eslint-disable */
import { Submenu } from './Submenu';

class SubmenuBuilder {
	constructor(menu) {
		this.menu = menu;
	}

	build(node) {
		const submenu = new Submenu();
		const linkNode = node;

		submenu
			.setMenu(this.menu)
			.setNode(node)
			.setLinkNode(linkNode)
			.init();

		return submenu;
	}
}

export { SubmenuBuilder };
